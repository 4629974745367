import { Fragment, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

import { TFunction } from "i18next";

import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import { Cog6ToothIcon } from "@heroicons/react/20/solid";
import {
  Bars3BottomLeftIcon,
  BellIcon,
  ChartBarIcon,
  CubeIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  RectangleGroupIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";

import Logo from "../components/Logo";
import AlertContainer from "../components/alert/AlertContainer";
import useAppZustand from "../stores/appZustand";
import NavigationPrimaryLarge from "./components/NavigationPrimaryLarge.tsx";
import NavigationPrimarySmall from "./components/NavigationPrimarySmall";
import UserDropDownMenu from "./components/UserDropDownMenu";
import LangSelect from "@/components/i18n/LangSelect";
import User from "@/models/User.ts";
import { IOrganization } from "@/types";
import { NavigationItem, UserNavigation } from "@/types/navigation";

const generateUserNavigation = (
  t: TFunction<"translation", undefined>,
  user: User,
): UserNavigation => {
  const userNavigations: UserNavigation = [
    {
      name: t("Front page"),
      href: "/",
      icon: HomeIcon,
      pattern: /^\/$/,
    },
  ];

  if (!user) return userNavigations;

  // Only show admin links to orgs you can manage for now, since it goes straight to the access page
  const orgs = (user.organizations ?? []).filter((org) => {
    return (org.users ?? []).find(
      (u) => u.id === user.id && u.roles.some((r) => ["owner", "manager"].includes(r)),
    );
  });
  //if (orgs.length > 0) {
  userNavigations.push({
    name: t("Organizations"),
    icon: RectangleGroupIcon,
    href: "#",
    pattern: /^\/organization/,
    children: [
      ...orgs.map(
        ({ id, name }): NavigationItem => ({
          name,
          href: `/organization/${id}/accounts`,
          pattern: new RegExp(`^/organization/${id}/`),
        }),
      ),
      {
        name: t("Add new"),
        href: "/organization/",
        pattern: /^\/organization\/$/,
      },
    ],
  });
  //}

  userNavigations.push({
    name: t("Settings"),
    href: `/user/${user.id}/edit`,
    icon: Cog6ToothIcon,
    pattern: /^\/user/,
  });
  /*
    {
      name: "Kunder",
      href: "#",
      icon: UsersIcon,
      current: false,
    },
    {
      name: "Projects",
      href: "#",
      icon: FolderIcon,
      current: false,
    },
    {
      name: "Filer",
      href: "#",
      icon: InboxIcon,
      current: false,
    },
    {
      name: "Reports",
      href: "#",
      icon: ChartBarIcon,
      current: false,
    },
     */

  return userNavigations;
};

function forceSmallSidebar(pathname: string) {
  return /^\/map\/(.*)$/.test(pathname);
}

type SidebarLayoutProps = {
  children?: ReactNode;
};

export default function SidebarLayout({ children }: SidebarLayoutProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { user } = useAppZustand(({ user }) => ({
    user,
  }));

  const { t } = useTranslation();

  // For certain pages (maps etc) we want to use the mobile sidebar
  let location = useLocation();
  const smallSidebar = forceSmallSidebar(location.pathname);
  const hideOrNot = smallSidebar ? "" : "lg:hidden";
  const plOrNot = smallSidebar ? "" : "lg:pl-64";

  const navigationItems = generateUserNavigation(t, user);

  return (
    <>
      <div className="h-full">
        <Transition show={sidebarOpen}>
          <Dialog as="div" className={`relative z-40 ${hideOrNot}`} onClose={setSidebarOpen}>
            <TransitionChild
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </TransitionChild>
            <TransitionChild
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="fixed inset-0 z-40 flex">
                <NavigationPrimarySmall setSidebarOpen={setSidebarOpen} items={navigationItems} />
                <div className="w-14 flex-shrink-0" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </TransitionChild>
          </Dialog>
        </Transition>

        {/* Static sidebar for desktop */}

        {!smallSidebar && <NavigationPrimaryLarge items={navigationItems} />}

        <div className={`flex flex-1 flex-col h-full ${plOrNot}`}>
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
            <button
              type="button"
              className={`border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-quincy-500 ${hideOrNot}`}
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4">
              <div className="flex flex-1">{/* Search */}</div>
              <div className="ml-4 flex items-center md:ml-6">
                {/*
                <button
                  type="button"
                  className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-quincy-500 focus:ring-offset-2"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                */}
                <LangSelect />
                <UserDropDownMenu />
              </div>
            </div>
          </div>

          <main className="grow">
            {/* grow is important for map */}
            {/* mx-auto max-w-7xl py-6 pl-4 sm:pl-6 md:pl-8 */}
            <div className="h-full">
              {/* Really the absolute is about map or non-map */}
              <AlertContainer
                extraClassNames={`map-float ${!sidebarOpen ? "z-30 absolute" : ""} top-30 left-10`}
              />
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
